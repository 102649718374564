
//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;

$base-font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$base-font-size: 16px;
$base-font-color: #333;
$base-background-color: #fff;

// google web font
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,300);
$base-font-family-en: 'Open Sans', sans-serif;

$key-color: #3f849b;
$key-color-bold: #18404e;
$base-color: #fff;
$ac-color: #cc3356;
$split-color: #d8d8d8;
$out-color: #888;
$frame-color: #f2f2f2;