.burger {
  display: block;
  padding: 21px;
}
.burger_content {
  display: block;
  position: relative;
  width: 28px;
  height: 20px;
  .burger_border {
    position: relative;
    display: block;
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      height: 4px;
      background: $key-color;
    }
    &:before {
      width: 4px;
      left: 0;
      opacity: 1;
      transition: left .25s ease .25s, opacity .25s ease 0s;
    }
    &:after {
      width: 28-6px;
      right: 0;
    }
  }
  .burger_border_top {
    &:before,
    &:after {
      top: 0;
    }
  }
  .burger_border_middle {
    &:before,
    &:after {
      top: 8px;
    }
    &:after {
      transition: opacity .25s ease 0s;
    }
  }
  .burger_border_bottom {
    &:before,
    &:after {
      top: 16px;
    }
  }
}

.is_open {
  .burger_border {
    &:before {
      transition: left .25s ease, opacity .25s ease .25s;
      left: 8px;
      opacity: 0;
    }
  }
  .burger_border_middle {
    &:after {
      transition: opacity .25s ease .5s;
      opacity: 0;
    }
  }
  .burger_border_top {
    &:after {
      transition: top .25s ease .5s, transform .25s ease .5s;
      top: 8px;
      transform: rotate(-45deg);
    }
  }
  .burger_border_bottom {
    &:after {
      transition: top .25s ease .5s, transform .25s ease .5s;
      top: 8px;
      transform: rotate(45deg);
    }
  }
}





.burger_content_animate {
  transition: transform .5s ease 0s;
  .burger_border {
    &:before,
    &:after {
    }
    &:before {
      opacity: 1;
      transition: left .25s ease .25s, opacity .25s ease 0s;
    }
    &:after {
    }
  }
  .burger_border_top {
    &:before{
      transition: left .25s ease, opacity .25s ease .25s;
    }
    &:after {
      transition: top .25s ease 0s, left .25s ease 0s, transform .25s ease 0s;
    }
  }
  .burger_border_middle {
    &:before{
      transition: left .25s ease, opacity .25s ease .25s;
    }
    &:after {
      transition: opacity .25s ease 0s;
    }
  }
  .burger_border_bottom {
    &:before{
      transition: left .25s ease, opacity .25s ease .25s;
    }
    &:after {
      transition: top .25s ease 0s, left .25s ease 0s, transform .25s ease 0s;
    }
  }
}

.is_open {
  .burger_content_animate {
    transition: transform .5s ease .5s;
    transform: rotate(-180deg);
    .burger_border {

    }
    .burger_border_top {
      &:before {
        transition: left .25s ease .2s, opacity .25s ease .25s;
        left: 8px;
        opacity: 0;
      }
      &:after {
        transition: top .25s ease .5s, right .25s ease .5s, transform .25s ease .5s;
        top: 8px;
        right: 3px;
        transform: rotate(-45deg);
      }
    }
    .burger_border_middle {
      &:before {
        transition: left .25s ease .1s, opacity .25s ease .25s;
        left: 8px;
        opacity: 0;
      }
      &:after {
        transition: opacity .25s ease .5s;
        opacity: 0;
      }
    }
    .burger_border_bottom {
      &:before {
        transition: left .25s ease 0s, opacity .25s ease .25s;
        left: 8px;
        opacity: 0;
      }
      &:after {
        transition: top .25s ease .5s, right .25s ease .5s, transform .25s ease .5s;
        top: 8px;
        right: 3px;
        transform: rotate(45deg);
      }
    }
  }
}