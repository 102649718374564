.tagcroud,
.wp-tag-cloud {
  margin-top: 8px;
  li {
    display: inline-block;
    margin: 8px 0 0 0;
    a {
      @extend .btn;
      @extend .btn_fill;
      @extend .btn_fill_hover-opacity;

    }
  }
}