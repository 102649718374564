.l_header {
  display: flex;
  width: 100%;
  height: auto;
}
.l_header_s {
  display: flex;
  width: 100%;
  height: auto;
}

.l_header_fit_s {
  padding-bottom: 64px;
}