.l_gnav {
  display: flex;
  width: auto;
}
.l_gnav_s {
  display: none;
  overflow-x: scroll;
  box-sizing: border-box;
  width: 200%;
  height: 100%;
  margin-left: -100%;
  padding-left: 100%;
  padding-right: 86px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: transform .5s ease 0s;
  transform: perspective(300px) rotate3d(0, 1, 0, 90deg);
  &.is_open {
    transition: transform .5s ease;
    transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
  }
}