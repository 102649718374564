.label {
  $label-height: 24px;
  display: inline-block;
  position: relative;
  @include font-size(12);
  color: $base-color;
  &:after {
    $label-after-width: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -($label-after-width/4)*3;
    content: '';
    border-top: $label-height/2 solid $ac-color;
    border-bottom: $label-height/2 solid transparent;
    border-left: $label-height/4 solid $ac-color;
    border-right: $label-height/4 solid transparent;
  }
  .label_content {
    display: inline-block;
    padding: 0 1rem;
    font-weight: 700;
    line-height: $label-height;
    background: $ac-color;
  }
  &.label_absolute {
    position: absolute;
    top: 4px;
    left: 0;
  }
}
