.thm {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
  .the_outline {
    border: 3px solid #ccc;
  }
}
.thm_fit {
  //overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  //height: 200px;
  img {
    display: block;
    margin: 0 auto;
  }
}

.thm_hover-filter {
  display: block;
  overflow: hidden;
  position: relative;
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($key-color, 0);
  }
  img {
    transform: rotate(0deg) scale(1.1);
    transition: transform .35s ease-in-out;
  }
  &:hover {
    &:after {
      transition: background .25s ease-in-out;
      background: rgba($key-color, .7);
    }
    img {
      transition: transform .5s ease-in-out;
      transform: scale(1.5);
    }
  }
}