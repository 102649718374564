.gnav {
  display: flex;
  width: 100%;
  ul {
    display: flex;
    li {
      display: flex;
      a{
        display: flex;
        padding: 0 1em;
        font-size: 1rem;
        color: $key-color;
        text-decoration: none;
        font-weight: 700;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.gnav_hover__underbar {
  li {
    a {
      display: block;
      position: relative;
      &:after {
        display: block;
        position: absolute;
        bottom: 1em;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: $key-color;
        transition: transform .15s ease-in-out;
        transform: rotateY(90deg);
      }
      &:hover {
        &:after {
          transform: rotateY(0deg);
        }
      }
    }
  }
}

.gnav_s {
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-bottom: 32px;
  background: $base_color;
  z-index: 99999;
  border-right: 1px solid #d8d8d8;
  ul {
    margin-top: 32px;
    border-top: 1px solid #d8d8d8;
  }
  li {
    display: block;
    a {
      display: block;
      @include font-size(14);
      color: $key-color;
      text-decoration: none;
      font-weight: 700;
      padding: 16px;
      border-bottom: 1px solid #d8d8d8;
    }
  }
}