.profile {
  img {
    display: block;
    box-sizing: border-box;
    margin: 16px auto 0;
    width: 100%;
    max-width: 256px;
    height: auto;
    border: 6px solid #ccc;
  }
  p {
    width: 100%;
    max-width: 256px;
    margin: 16px auto 0;
    @include font-size(14);
    line-height: 1.5em;
    letter-spacing: .05em;
  }
  .profile_title {
    margin-top: 8px;
    text-align: center;
  }
  .profile_sns {
    margin-top: 8px;
  }
}