.footer {
  margin-top: 32px;
  padding: 64px 16px 32px;
  border-top: 1px solid #d8d8d8;
}
.footer_content {
  width: 100%;
  text-align: center;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}


.footer_s {
  padding: 32px 16px;
  border-top: 1px solid #d8d8d8;
}