.search {
  margin-top: 16px;
  text-align: center;
  font-size: 0;
  input {
    display: inline-block;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}
input {
  margin: 8px 0 0 0;
  padding: 8px 16px;
  @include font-size(14);
  line-height: 1.5em;
  letter-spacing: .05em;
}
input[type=text] {
  width: 100%;
  max-width: 128px;
  border: 1px solid #d8d8d8;
}
input[type=submit] {

}