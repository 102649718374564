.l_content {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-width: 960px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}
.l_content_s {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}