.l_split {
  display: flex;
  .l_split_col {
    flex: 1;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.sp {
  .l_split {
    display: block;
    .l_split_col{
      margin: 16px 0 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}