.title {
  @include font-size(24);
  line-height: 1.5em;
  font-weight: 700;
}
.title_heading {
  color: $key-color;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.title_list {
  @include font-size(14);
  &.title_list_sp {
    color: $base-font-color;
    @include font-size(12);
  }
}

.title_frame {
  padding: 8px 16px;
  background: $frame-color;
  @include  font-size(20);
  color: $key-color-bold;
}
.title_frame_fill {
  background: $key-color;
  color: $base-color;
}

.sp {
  .titile {
    @include font-size(18);
  }
  .title_frame {
    @include font-size(18);
  }
}