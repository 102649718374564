/*
#styleguide
icon

```
<i class="icon web-navigation-line-craft-1-109681"></i>
<i class="icon web-navigation-line-craft-2-109682"></i>
<i class="icon web-navigation-line-craft-3-109688"></i>
<i class="icon web-navigation-line-craft-4-109689"></i>
<i class="icon web-navigation-line-craft-5-109690"></i>
```
*/

$web-navigation-line-craft-1-109681: 0px 0px 0px 0px 512px 512px 1548px 1030px '../images/icon.png' web-navigation-line-craft-1-109681;
$web-navigation-line-craft-2-109682: 518px 0px -518px 0px 512px 512px 1548px 1030px '../images/icon.png' web-navigation-line-craft-2-109682;
$web-navigation-line-craft-3-109688: 0px 518px 0px -518px 512px 512px 1548px 1030px '../images/icon.png' web-navigation-line-craft-3-109688;
$web-navigation-line-craft-4-109689: 518px 518px -518px -518px 512px 512px 1548px 1030px '../images/icon.png' web-navigation-line-craft-4-109689;
$web-navigation-line-craft-5-109690: 1036px 0px -1036px 0px 512px 512px 1548px 1030px '../images/icon.png' web-navigation-line-craft-5-109690;


$icon: ($web-navigation-line-craft-1-109681,$web-navigation-line-craft-2-109682,$web-navigation-line-craft-3-109688,$web-navigation-line-craft-4-109689,$web-navigation-line-craft-5-109690,);
@each $sprite in $icon {
    $name: nth($sprite,10);
    .icon.#{$name} {
        @include sprite($sprite,1);
    }
}