.article {
  position: relative;
  box-sizing: border-box;
  .article_title {
    margin-top: 8px;
    padding: 0 32px;
  }
  .article_thm {
    box-sizing: border-box;
    margin-top: 16px;
    padding: 0 32px;
    width: 100%;
  }
  .article_content {
    margin-top: 16px;
    padding: 0 32px;
  }
  .article_sns {
    margin-top: 16px;
    padding: 0 32px;
  }
  .article_btn {
    margin-top: 16px;
    padding: 0 32px;
    text-align: right;
  }
  .article_aside {
    margin-top: 32px;
    padding: 0 32px;
  }
}

.article_section {
  margin-bottom: 32px;
  padding: 0 0 32px 0;
  border: 1px solid $split-color;
  background: rgba($key-color, .05);
}

.article_list {
  display: flex;
  margin-top: 16px;
  &.article_list_margin {
    padding: 0 16px;
  }
  a {
    display: flex;
    text-decoration: none;
    color: $key-color;
  }
  .article_list_thm {
    margin: 0;
    padding: 0;
    width: auto;
  }
  .article_list_thm_small {
    img {
      width: 96px;
      height: auto;
    }
  }
  .article_list_thm_middle {
    img {
      width: 128px;
      height: auto;
    }
  }
  .article_list_detail {
    flex: 1;
  }
  .article_list_title {
    margin: 8px 0 0 8px;
    padding: 0;
  }
  .article_list_time {
    margin: 8px 0 0 8px;
    &.article_list_time_sp {
      color: $base-font-color;
      @include font-size(12);
    }
  }
  &.article_list_split {
    padding-bottom: 16px;
    border-bottom: 1px solid $split-color;
  }
}

.article_list_hover-block {
  a {
    &:hover {
      text-decoration: underline;
      img {
        opacity: .75;
      }
    }
  }

}

.article_content {
  h2 {
    margin-top: 32px;
    padding: 8px 16px;
    background: $key-color;
    @include font-size(20);
    line-height: 1.5em;
    letter-spacing: .05em;
    font-weight: 700;
    color: $base-color;
  }
  h3 {
    margin-top: 32px;
    padding: 8px 16px;
    border-left: 2px solid $key-color;
    @include font-size(20);
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: .05em;
    color: $key-color;
  }
  h4 {
    margin-top: 32px;
    @include font-size(20);
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: .05em;
  }
  p {
    margin-top: 16px;
    @include font-size(16)
    line-height: 1.5em;
    letter-spacing: .05em;
  }
  a {
    color: $ac-color;
    text-decoration: underline;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  del {
    text-decoration: line-through;
    color: #999;
  }
  blockquote {
    margin-top: 16px;
    padding: 16px;
    background: #f2f2f2;
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul, ol {
    margin-top: 16px;
    li {
      margin-top: 16px;
      line-height: 1.5em;
      letter-spacing: .05em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul {

    li {
      position: relative;
      margin-left: 0;
      padding-left: 1em;
      &:before {
        position: absolute;
        top: .5em;
        left: 0;
        display: block;
        content: '';
        width: .5em;
        height: .5em;
        background: $key-color;
        transform: rotate(45deg);
      }
    }
  }
  ol {
    padding-left: 1em;
    list-style-type: decimal;
  }
  $table-border-color: #ccc;
  .table_scroll {
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f2f2f2;
    }
    &::-webkit-scrollbar-thumb {
      background: $table-border-color;
    }
  }
  table {
    width: 100%;
    margin-top: 32px;
    border-top: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;
    th, td {
      box-sizing: border-box;
      padding: .5em;
      border-right: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
    th {
      background: #f2f2f2;
      font-weight: 700;
    }
  }
  hr {
    margin-top: 32px;
    margin-bottom: 64px;
    border: 1px solid #f2f2f2;
  }
  img {
    width: 100%;
    height: auto;
  }
  iframe {
    margin-top: 32px;
    width: 100%;
    max-height: 512px;
  }
  // ニコニコの動画共有プレイヤー用
  .nicovideo {
    margin-top: 32px !important;
    text-align: center;
  }
}

.article_content_s {
  h2 {
    @include font-size(18);
  }
  h3 {
    @include font-size(18);
  }
  h4 {
    @include font-size(18);
  }
  p {
    @include font-size(14)
  }
  ol {
    padding-left: 1em;
    list-style-type: decimal;
  }
  iframe {
    max-height: 256px !important;
  }
  // ニコニコの動画共有プレイヤー用
  .nicovideo {
    > div {
      width: 100% !important;
      height: auto;
    }
  }
}

.article_fit {
  a {
    text-decoration: none;
  }
  .article_fit_img {
    &.is_fitWidth {
      width: 100% !important;
      height: auto !important;
    }
    &.is_fitHeight {
      height: 100% !important;
      height: auto !important;
    }
  }
}
.sp {
  .article_fit {
    .article_fit_title {
      @include font-size(12);
    }
  }
}