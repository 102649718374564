%btn-base-fonts {
  padding: 8px 1em;
  @include font-size(14);
  font-weight: 700;
  color: $key-color;
  text-decoration: none;
}
.btn {
  box-sizing: border-box;
  display: inline-block;
  background: rgba($key-color, 0);
  text-align: center;
  @extend %btn-base-fonts;
  border: 2px solid $key-color;
}

.btn_hover-up {
  overflow: hidden;
  position: relative;
  transition: background .2s linear .2s;
  &::before {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    content: attr(data-hover);
    @extend %btn-base-fonts;
    text-align: center;
    transition: transform .4s, opacity .4s;
    transition-timing-function: cubic-bezier(.75, 0, .125, 1);
    box-sizing: border-box;
  }
  span {
    display: inline-block;
    transform: translate3d( 0, 1rem, 0);
    transition: transform .4s, opacity .4s;
    transition-timing-function: cubic-bezier(.75, 0, .125, 1);
    opacity: 0;
    color: $base-color;
  }
  &:hover {
    background: $key-color;
    &::before {
      transform: translate3d( 0, -50%, 0);
      opacity: 0;
    }
    span {
      transform: translate3d(0, 0, 0);
      opacity: 1;

      @for $i from 1 through 16 {
        &:nth-child(#{$i}){
          transition-delay: #{$i*.1}s;
        }
      }

    }
  }
}
.btn_hover-fill {
  transition: color .25s, background .25s;
  &:hover {
    color: $base-color;
    background: rgba($key-color, 1);
  }
}


.btn_fill {
  background: rgba($key-color, 1);
  color: $base-color;
}
.btn_fill_hover-opacity {
  transition: background .25s, color .25s;
  &:hover {
    background: rgba($key-color, 0);
    color: $key-color;
  }
}

.btn_fit {
  width: 100%;
}

.btn_article-card {
  display: flex;
  .btn_article-card_thm {
    img {
      width: 64px;
      height: 64px;
    }
  }
  .btn_article-card_detail {
    overflow: hidden;
    height: 64px;
    margin-left: 8px;
    flex: 1;
  }
  .btn_article-card_msg {
    margin-top: 8px;
    padding-bottom: 4px;
    @include font-size(14);
    line-height: 1em;
    letter-spacing: .05em;
    border-bottom: 1px solid $key-color;
  }
  .btn_article-card_title {
    margin-top: 8px;
    @include font-size(10);
    line-height: 1.3em;
    letter-spacing: .05em;
    text-align: left;
  }
}
.btn_article-card_hover-fill {
  background: rgba($key-color, 0);
  transition: background .25s, color .25s;
  .btn_article-card_thm {
    position: relative;
    &::after{
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      border: 0 solid rgba(#fff, .25);
      transition: border .25s;
    }
  }
  .btn_article-card_msg {
    transition: border .25s;
  }
  &:hover {
    background: rgba($key-color, 1);
    color: #fff;
    .btn_article-card_thm {
      &::after {
        border: 8px solid rgba(#fff, .25);
      }
    }
    .btn_article-card_msg {
      border-color: #fff;
    }
  }
}

.btn_inline {
  display: inline-block;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}