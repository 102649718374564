.header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  background: $base-color;
  z-index: 9999;
}
.header_s {
  position: relative;
  margin-top: -63px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  background: $base-color;
  &.is_fixed {
    position: fixed;
    margin-top: 0 !important;
    top: 0 !important;
    z-index: 999;
  }
}
