.info-bar {
  display: flex;
  .info-bar_label {
    margin-right: auto;
  }
  .info-bar_time {
    padding-right: 32px;
    font-family: $base-font-family-en;
    @include font-size(14);
    line-height: 24px;
    color: $out-color;
  }
}