.logo {
  width: 100%;
  height: auto;
  a {
    display: block;
    margin: 16px 0;
  }
}

.logo_hover__heart {
  display: block;
  &:hover {
     img {
      animation: heart .25s ease 0s 2 normal;
    }
  }
}

@keyframes heart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}