.subContent {
  position: relative;
}
.subContent_title {
  margin-top: 32px;
}
.subContent_inner {
  margin-top: 64px;
  &.subContent_inner_first {
    margin-top: 0;
  }
  &.subContent_inner_fix {
    transition: top 1s;
    position: relative;
    padding: 10px;
  }


}
.subContent_inner_center {
  text-align: center;
}