.l_logo {
  width: 100%;
  max-width: 256px;
  margin-right: auto;
  transition: width 1s;
}
.is_scroll {
  .l_logo {
    width: 128px;
  }
}
.l_logo_s {
  width: 100%;
  max-width: 128px;
  margin-right: auto;
}
.l_logo_center_s {
  box-sizing: border-box;
  width: 100%;
  max-width: 256px;
  margin: 0 auto;
  padding: 32px 16px 0;
}